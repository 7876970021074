/* src/components/Login.css */

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh; /* Ensure it takes at least full viewport height */
    background-color: #f0f0f0; /* Light background color */
    margin: 0; /* Remove default margin */
    padding: 0; /* Remove default padding */
    overflow: hidden; /* Prevent scrolling */
  }
  
  .login-form {
    background: white;
    padding: 2rem;
    border-radius: 12px; /* Slightly more rounded corners */
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2); /* Enhanced shadow for depth */
    width: 100%; /* Full width to ensure it takes available space */
    max-width: 400px; /* Max width for larger screens */
    text-align: center;
    transition: transform 0.3s; /* Smooth transform effect */
  }
  
  .login-title {
    margin-bottom: 1.5rem;
    font-size: 24px;
    font-weight: bold;
    color: #333; /* Dark text color */
  }
  
  .login-input {
    width: 100%; /* Full width */
    padding: 0.75rem; /* Consistent padding */
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    transition: border-color 0.3s;
  }
  
  .login-input:focus {
    border-color: #007bff; /* Highlight border on focus */
    outline: none; /* Remove default outline */
  }
  
  .login-button {
    width: 100%; /* Full width for button */
    padding: 0.75rem;
    background-color: #007bff; /* Primary button color */
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
  }
  
  .login-button:hover {
    background-color: #0056b3; /* Darken button on hover */
    transform: translateY(-2px); /* Lift effect on hover */
  }
  
  .error-message {
    color: red; /* Error message color */
    margin-top: 1rem;
  }
  
  .success-message {
    color: green; /* Success message color */
    margin-top: 1rem;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .login-container {
      padding: 1rem; /* Add padding on smaller screens */
    }
  
    .login-form {
      width: 90%; /* Make form responsive for small screens */
      max-width: 95%; /* Max width for better fit */
      transform: translateY(-20px); /* Slightly raise the form */
    }
  }
  
  @media (max-width: 480px) {
    .login-title {
      font-size: 20px; /* Slightly smaller title for mobile */
    }
  
    .login-input {
      font-size: 14px; /* Smaller input font for better fit */
    }
  
    .login-button {
      font-size: 14px; /* Smaller button font for consistency */
    }
  }
  