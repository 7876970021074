/* General Styling */
.bill-details-container {
    padding: 16px;
    max-width: 800px;
    margin: auto;
  }
  
  .bill-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
  
  .bill-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  /* Button Styling */
  .invoice-button,
  .scan-button,
  .create-product-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 16px;
    font-size: 0.9rem;
    margin: 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: inline-block;
  }
  
  .invoice-button:hover,
  .scan-button:hover,
  .create-product-button:hover {
    background-color: #45a049;
  }
  
  .scan-button {
    background-color: #2196F3;
  }
  
  .scan-button:hover {
    background-color: #1e88e5;
  }
  
  .create-product-button {
    background-color: #f44336;
  }
  
  .create-product-button:hover {
    background-color: #d32f2f;
  }
  
  /* Table Styling */
  .product-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .product-table th, .product-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .product-table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  /* QR Code Scanner */
  #qr-reader {
    width: 100%;
    max-width: 400px;
    height: auto;
    margin: 20px auto;
  }
  
  /* Responsive Design */
  @media only screen and (max-width: 600px) {
    .bill-header {
      flex-direction: column;
      align-items: stretch;
    }
  
    .bill-info p {
      font-size: 0.9rem;
    }
  
    .bill-title {
      font-size: 1.2rem;
    }
  
    .invoice-button,
    .scan-button,
    .create-product-button {
      width: 100%;
      padding: 12px;
      font-size: 1rem;
    }
  
    .product-table th, .product-table td {
      font-size: 0.85rem;
      padding: 6px;
    }
  }
  