/* Navbar.css */

/* Basic styles for the navbar */
.nav-navbar {
  background-color: #333; /* Background color for the navbar */
  color: white; /* Text color */
  padding: 10px; /* Padding for the navbar */
  display: flex; /* Flexbox layout */
  justify-content: space-between; /* Space between items */
  align-items: center; /* Center items vertically */
  position: relative; /* Positioning context for dropdown */
}

/* Styles for the logo */
.logo {
  font-size: 24px; /* Logo font size */
}

/* Style for the menu toggle button */
.menu-toggle {
  display: none; /* Hide by default */
  background: none;
  border: none;
  color: white;
  font-size: 24px; /* Adjust font size for the button */
  cursor: pointer;
  transition: transform 0.2s; /* Optional smooth transition */
}

/* Add some hover effect */
.menu-toggle:hover {
  transform: scale(1.1); /* Slightly enlarge on hover */
}

/* Styles for the navigation list */
.nav-list {
  list-style: none; /* Remove default list styling */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margin */
  display: flex; /* Flexbox for list items */
  gap: 10px; /* Space between items */
}

/* Styles for individual links */
.nav-list li {
  position: relative; /* Positioning context for the link effects */
}

.nav-list a {
  color: white; /* Link color */
  text-decoration: none; /* Remove underline */
  padding: 10px 15px; /* Add padding for clickable area */
  border-radius: 5px; /* Rounded corners */
  transition: background-color 0.3s, transform 0.2s; /* Smooth transitions */
}

/* Hover effects for links */
.nav-list a:hover {
  background-color: rgba(255, 255, 255, 0.2); /* Light background on hover */
  transform: translateY(-2px); /* Slightly lift on hover */
}

/* Responsive styling */
@media (max-width: 768px) {
  .menu-toggle {
    display: block; /* Show menu button on mobile */
  }

  .nav-list {
    display: none; /* Hide nav list by default on mobile */
    flex-direction: column; /* Stack items vertically */
    position: absolute; /* Position it absolutely */
    top: 200px; /* Position below the navbar */
    left: 0;
    right: 0;
    background-color: rgba(51, 51, 51, 0.95); /* Slightly transparent background for dropdown */
    border-radius: 8px; /* Rounded corners */
    z-index: 1000; /* Ensure it appears above other content */
    width: 60%; /* Adjust width for a smaller dropdown */
    margin: auto; /* Center the dropdown */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow for depth */
    padding: 20px; /* Padding for the dropdown */
    transform: translateY(-10px); /* Slightly lift the dropdown */
  }

  .nav-list.open {
    display: flex; /* Show nav list when open */
  }

  .nav-list li {
    margin: 10px 0; /* Space between vertical items */
    text-align: center; /* Center text for links */
  }
}

/* Backdrop for the dropdown */
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent backdrop */
  z-index: 500; /* Behind the dropdown but above other content */
}

/* Style for user name display */
.user-name {
  margin-left: auto; /* Align to the right */
}