/* BillDetails.css */

.bill-details-container {
    max-width: 800px; /* Set max width for the container */
    margin: 0 auto; /* Center the container */
    padding: 20px; /* Padding around the container */
    font-family: 'Arial', sans-serif; /* Set a clean font */
  }
  
  .bill-header {
    display: flex; /* Use flexbox for header layout */
    justify-content: space-between; /* Space between title and button */
    align-items: center; /* Center items vertically */
    padding-bottom: 20px; /* Space below header */
    border-bottom: 2px solid #4CAF50; /* Green border below header */
  }
  
  .bill-title {
    font-size: 24px; /* Title font size */
    color: #4CAF50; /* Green color for title */
    margin: 0; /* Remove margin */
  }
  
  .invoice-button {
    padding: 10px 15px; /* Button padding */
    font-size: 16px; /* Font size for button */
    color: white; /* Button text color */
    background-color: #4CAF50; /* Green background */
    border: none; /* Remove default border */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease; /* Transition for hover effect */
  }
  
  .invoice-button:hover {
    background-color: #45a049; /* Darker green on hover */
  }
  
  .bill-info {
    background-color: #f9f9f9; /* Light gray background for info section */
    padding: 15px; /* Padding around info */
    border-radius: 5px; /* Rounded corners */
    margin-bottom: 20px; /* Margin below info section */
  }
  
  .product-table {
    width: 100%; /* Full width */
    border-collapse: collapse; /* Remove space between borders */
    margin: 20px 0; /* Margin around the table */
  }
  
  .product-table th, .product-table td {
    border: 1px solid #ddd; /* Light gray border */
    padding: 12px; /* Padding inside cells */
    text-align: left; /* Align text to the left */
  }
  
  .product-table th {
    background-color: #f2f2f2; /* Light gray background for header */
    color: #333; /* Dark text color */
  }
  
  .product-table tr:nth-child(even) {
    background-color: #f9f9f9; /* Alternate row color */
  }
  
  .product-table tr:hover {
    background-color: #f1f1f1; /* Highlight row on hover */
  }
  
  .qr-input {
    width: 100%; /* Full width */
    padding: 10px; /* Padding inside input */
    border: 1px solid #ccc; /* Light gray border */
    border-radius: 5px; /* Rounded corners */
    margin-bottom: 20px; /* Margin below input */
  }
  
  .error-message {
    color: red; /* Red color for error messages */
  }
  
  /* Responsive Styles */
  @media (max-width: 600px) {
    .bill-header {
      flex-direction: column; /* Stack items in column */
      align-items: flex-start; /* Align items to the start */
    }
  
    .invoice-button {
      width: 100%; /* Full width for button */
      margin-top: 10px; /* Margin above button */
    }
  }
  