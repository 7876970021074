/* TodayBillsPage.module.css */

.todayBillsContainer {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .todayBillsTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .todayBillsTable th,
  .todayBillsTable td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }
  
  .todayBillsTable th {
    background-color: #4CAF50;
    color: white;
  }
  
  .billButton {
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .billButton:hover {
    background-color: #45a049;
  }
  