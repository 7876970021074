.qr-scanner-container {
    position: relative;
    width: 100%;
    max-width: 500px;
    margin: auto;
}

.qr-reader {
    width: 100%;
    height: auto;
}

.qr-box {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 250px; /* Adjust size as needed */
    height: 250px; /* Adjust size as needed */
    transform: translate(-50%, -50%);
    border: 2px solid red; /* Red border */
    box-shadow: 0 0 10px rgba(255, 0, 0, 0.5); /* Optional shadow for effect */
}

.red-line {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 2px; /* Adjust thickness as needed */
    background-color: red; /* Red color */
    z-index: 1; /* Make sure it is on top of the video */
}
