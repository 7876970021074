/* BillPrint.css */

.bill-print-container {
    max-width: 800px; /* Max width for print layout */
    margin: 0 auto; /* Center the container */
    padding: 20px; /* Padding around the container */
    font-family: 'Arial', sans-serif; /* Font style */
    color: #333; /* Text color */
  }
  
  .bill-info {
    background-color: #f9f9f9; /* Light gray background for info section */
    padding: 15px; /* Padding around info */
    border-radius: 5px; /* Rounded corners */
    margin-bottom: 20px; /* Margin below info section */
  }
  
  .product-table {
    width: 100%; /* Full width */
    border-collapse: collapse; /* Remove space between borders */
    margin: 20px 0; /* Margin around the table */
  }
  
  .product-table th, .product-table td {
    border: 1px solid #ddd; /* Light gray border */
    padding: 12px; /* Padding inside cells */
    text-align: left; /* Align text to the left */
  }
  
  .product-table th {
    background-color: #f2f2f2; /* Light gray background for header */
    color: #333; /* Dark text color */
  }
  
  .product-table tr:nth-child(even) {
    background-color: #f9f9f9; /* Alternate row color */
  }
  
  .product-table tr:hover {
    background-color: #f1f1f1; /* Highlight row on hover */
  }
  
  .print-button {
    padding: 10px 15px; /* Button padding */
    font-size: 16px; /* Font size for button */
    color: white; /* Button text color */
    background-color: #4CAF50; /* Green background */
    border: none; /* Remove default border */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    margin-top: 20px; /* Margin above button */
  }
  
  .print-button:hover {
    background-color: #45a049; /* Darker green on hover */
  }
  
  /* Hide elements that should not appear in print */
  @media print {
    .print-button {
      display: none; /* Hide print button when printing */
    }
  }
  