/* Invoice.css */

/* Container for the invoice */
.invoice-container {
    margin: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  /* Heading styles */
  .invoice-container h2 {
    color: #333;
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  /* Table styles */
  .products-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  .products-table th, .products-table td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
  }
  
  .products-table th {
    background-color: #f2f2f2;
  }
  
  /* Form styles */
  .add-product-form {
    margin-top: 20px;
  }
  
  .add-product-form label {
    display: block;
    margin-bottom: 5px;
  }
  
  .add-product-form input,
  .add-product-form select {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .add-product-form button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .add-product-form button:hover {
    background-color: #218838;
  }
  

  /* Invoice.css */

/* Other styles remain unchanged */

/* Form styles */
.add-product-form {
    margin-top: 20px;
  }
  
  .form-row {
    display: flex; /* Use flexbox for horizontal layout */
    align-items: center; /* Align items vertically centered */
    gap: 15px; /* Add space between items */
  }
  
  .form-group {
    display: flex;
    flex-direction: column; /* Stack label above input */
    flex: 1; /* Allow each input to take equal space */
  }
  
  .add-product-form label {
    margin-bottom: 5px; /* Space between label and input */
  }
  
  .add-product-form input,
  .add-product-form select {
    padding: 8px; /* Adjust padding */
    border: 1px solid #ccc; /* Maintain border styling */
    border-radius: 4px; /* Maintain border radius */
  }
  
  .add-product-form button {
    background-color: #28a745; /* Button color */
    color: white; /* Button text color */
    border: none; /* Remove border */
    padding: 10px 15px; /* Button padding */
    border-radius: 4px; /* Button border radius */
    cursor: pointer; /* Change cursor to pointer on hover */
  }
  
  .add-product-form button:hover {
    background-color: #218838; /* Button hover color */
  }
  
    /* Responsive Styles */
    @media (max-width: 600px) {
        .bill-header {
          flex-direction: column; /* Stack items in column */
          align-items: flex-start; /* Align items to the start */
        }
      
        .invoice-button {
          width: 100%; /* Full width for button */
          margin-top: 10px; /* Margin above button */
        }
      }
      