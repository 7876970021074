/* ProductCreate.css */

/* Base styling for the ProductCreate component */
.product-create {
    font-family: Arial, sans-serif; /* Font for the whole component */
    background-color: #f5f5f5; /* Light grey background */
    padding: 20px; /* Add padding to the component */
}

/* Styling for the heading */
.product-create h1 {
    color: #333; /* Dark grey color for the heading */
    text-align: center; /* Center align the heading */
    margin-bottom: 20px; /* Space below the heading */
}

/* Form container styling */
.product-create form {
    background-color: #fff; /* White background for the form */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    padding: 20px; /* Padding inside the form */
    max-width: 400px; /* Max width for the form */
    margin: 0 auto; /* Center the form horizontally */
}

/* Label styling */
.product-create label {
    display: block; /* Block display for labels */
    margin-bottom: 8px; /* Space below each label */
    font-weight: bold; /* Bold labels */
    color: #555; /* Medium grey color for labels */
}

/* Input field styling */
.product-create input[type="text"],
.product-create input[type="number"] {
    width: 100%; /* Full width */
    padding: 10px; /* Padding inside the input */
    margin-bottom: 15px; /* Space below each input */
    border: 1px solid #ddd; /* Light grey border */
    border-radius: 4px; /* Rounded corners for inputs */
    font-size: 16px; /* Font size for input text */
}

/* Checkbox styling */
.product-create input[type="checkbox"] {
    margin-right: 10px; /* Space between checkbox and label */
}

/* Button styling */
.product-create button {
    background-color: #28a745; /* Bootstrap success color */
    color: white; /* White text color */
    border: none; /* No border */
    padding: 10px 15px; /* Padding around button text */
    border-radius: 4px; /* Rounded corners for button */
    font-size: 16px; /* Font size for button text */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease; /* Smooth background color transition */
    width: 100%; /* Make the button full width */
}

/* Button hover effect */
.product-create button:hover {
    background-color: #218838; /* Darker shade on hover */
}

/* Message styling */
.product-create .success-message {
    color: green; /* Green color for success messages */
    text-align: center; /* Center align success message */
    margin-bottom: 15px; /* Space below success message */
}

.product-create .error-message {
    color: red; /* Red color for error messages */
    text-align: center; /* Center align error message */
    margin-bottom: 15px; /* Space below error message */
}
