/* ProductDetails.css */

.product-details-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

h2 {
    text-align: center;
    color: #333;
}

.product-details {
    margin-bottom: 20px;
}

.product-details p {
    margin: 10px 0;
    font-size: 16px;
    color: #555;
}

label {
    font-weight: bold;
}

input[type="text"],
input[type="number"] {
    width: calc(100% - 22px); /* Adjust width to accommodate padding */
    padding: 10px;
    margin: 5px 0 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
}

input[type="checkbox"] {
    margin-right: 10px;
}

button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-right: 10px;
}

button:hover {
    background-color: #0056b3;
}

.error-message {
    color: red;
    font-weight: bold;
}

.success-message {
    color: green;
    font-weight: bold;
}
